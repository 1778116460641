import React from 'react';
import ContactPage from './ContactPage';

const Contact = () => {

  return (
    <div className="pt-32 pb-12 pr-6 pl-6 lg:pr-28 lg:pl-28 xl:pr-28 xl:pl-28 bg-[#ded1be] font-raleway">
      <ContactPage />
    </div>
  );
};

export default Contact;