import React from 'react';
import AboutPage from './AboutPage';

const About = () => {
  return (
    <div className="pt-32 bg-[#ded1be]">
      <AboutPage />
    </div>
  )
}

export default About