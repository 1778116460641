import React from "react";
import Heading2 from "components/Heading/Heading2";

const EventsPage = () => {
  return (
    <section className="bg-[#ded1be] pb-20 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center">
          <h1 className="mb-4 text-[#101213] text-4xl font-bold sm:text-5xl md:text-5xl xl:text-5xl lg:text-4xl md:leading-tight font-heptaslab">
            Events
          </h1>
          <p className="mb-8 text-lg font-normal text-[#101213] lg:text-xl sm:px-16 xl:px-48">
            We create platforms where stories are told, ideas are inspired, and change is pioneered.  Join us for immersive and electrifying experiences across our many events across the world.
          </p>
        </div>
        
        <section>
          <Heading2 heading="BCP Origins" />
          <div className="grid grid-cols-1 gap-8 text-[#101213]">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold">Brands, Customers and Products</h1>
              <p className="font-medium text-[18px] mb-4">The pilot edition of BCP which had 120 people in attendance was the birth of this idea. We discussed the basic concept surrounding how great brands are built.</p>
              <a href="https://drive.google.com/drive/folders/15sORpdAxQNZgFFp-Bpk5-28_zU_xyO29" target="_blank" rel="noopener" className="text-[18px] font-semibold underline hover:text-[#83977e]"><p>Relive the moment</p></a>
            </div>
          </div>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />

          <div className="grid grid-cols-1 gap-8 text-[#101213] sm:gap-12">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold">BCP ‘23: Building Global Brands Locally</h1>
              <p className="font-medium text-[18px] mb-4">At BCP ‘23, we hosted 2,170 people and a line up of 25 speakers to discuss how we can build global brands.</p>
              <a href="https://drive.google.com/drive/folders/1c3GCGKcEHStxdFkaDP40OhOR5Wl6F4sB" target="_blank" rel="noopener" className="text-[18px] font-semibold underline hover:text-[#83977e]"><p>Relive the moment</p></a>
            </div>
          </div>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />

          <div className="grid grid-cols-1 gap-8 text-[#101213] sm:gap-12">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold">BCP ‘24: Accessing Global Opportunities</h1>
              <p className="font-medium text-[18px] mb-4">The Third edition of our flagship creative story conference BCP happens in Akure, Ondo State. Our goal is to facilitate an experience where people discover and learn how to utilize global opportunities.</p>
              <a href="https://tix.africa/discover/bcp24" target="_blank" rel="noopener" className="text-[18px] font-semibold underline hover:text-[#83977e]"><p>Attend event</p></a>
            </div>
          </div>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />

          <div className="grid grid-cols-1 gap-8 text-[#101213] sm:gap-12">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold">BCP 4.0: From Zero to Global Talent </h1>
              <p className="font-medium text-[18px] mb-4">Join us for an immersive experience where we discuss how talent and career evolves.</p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSd2OhaKk80CtN6qHnHuArVgzI-oc1yRis-dj4BNXgN_mT7iww/viewform?usp=pp_url" target="_blank" rel="noopener" className="text-[18px] font-semibold underline hover:text-[#83977e]"><p>Join the waitlist</p></a>
            </div>
          </div>
        </section>

        <hr className="h-0.5 my-12 bg-[#83977e] border-0" />

        <section>
          <Heading2 heading="BCP Origins: Mini Experiences" />
          <div className="grid grid-cols-1 gap-8 text-[#101213]">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold">Host or locate a BCP Mini Experience near you</h1>
              <p className="font-medium text-[18px] mb-4">We made it possible for you to create a platform where stories are shared in your region, or join an experience and hear amazing stories shared by local talents building global brands.</p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSd0sCXAjIQberWwygSYIEEmeeugQjVHCHBt-xxVj112sWK9PQ/viewform" target="_blank" rel="noopener" className="text-[18px] font-semibold underline hover:text-[#83977e]"><p>Host a BCP Mini Experience</p></a>
              <a href="https://drive.google.com/drive/folders/1Pb2Eo8CmJxiIvcpSlE1hHH4IvfwshjJI" target="_blank" rel="noopener" className="text-[18px] font-semibold underline hover:text-[#83977e]"><p className="mt-3">Explore our other regional BCP Mini Experiences</p></a>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default EventsPage;
