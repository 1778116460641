import React from "react";
import Heading3 from "../../components/Heading/Heading3";

const Contact = () => {
  return (
    <section className="bg-[#ded1be] pb-6 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <Heading3
          heading="Contact us"
          subHeading={
            <div>
              <p className="block font-raleway mt-3 text-[#101213]">
                Reach us for partnership, sponsorship or any other thing here:
              </p>
              <a
                href="mailto:toluwase@bcporigins.com "
                target="_blank"
                rel="noopener"
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p className="mt-4">toluwase@bcporigins.com </p>
              </a>
            </div>
          }
        />
      </div>
    </section>
  );
};

export default Contact;