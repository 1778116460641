import React, { useState, useEffect, useRef } from "react";
import one from "../../images/community/1.jpg";
import two from "../../images/community/2.jpg";
import three from "../../images/community/3.jpg";
import four from "../../images/community/4.jpg";
import five from "../../images/community/5.jpg";
import six from "../../images/community/6.jpg";
import seven from "../../images/community/7.jpg";
import eight from "../../images/community/8.jpg";
import nine from "../../images/community/9.jpg";
import ten from "../../images/community/10.jpg";
import eleven from "../../images/community/11.jpg";
import twelve from "../../images/community/12.jpg";
import thirteen from "../../images/community/13.jpg";
import fourteen from "../../images/community/14.jpg";
import fifteen from "../../images/community/15.jpg";
import sixteen from "../../images/community/16.jpg";
import seventeen from "../../images/community/17.jpg";
import eighteen from "../../images/community/18.jpg";
import nineteen from "../../images/community/19.jpg";

const CommunityPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 19; // Total number of slides
  const slideInterval = 5000; // Interval between slides in milliseconds
  let timerRef = useRef(null);

  const carouselImages = [ten, eleven, one, two, three,four, five, six, seven, eight, nine, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen];

  useEffect(() => {
    const slideNext = () => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    };

    timerRef.current = setInterval(slideNext, slideInterval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [totalSlides, slideInterval]);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
    clearInterval(timerRef.current);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    clearInterval(timerRef.current);
  };

  const handleSlideClick = (index) => {
    setCurrentSlide(index);
    clearInterval(timerRef.current);
  };

  return (
    <section className="bg-[#ded1be] pb-32 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <div className="px-4 py-8 mx-auto text-center">
          <h1 className="mb-4 text-[#101213] text-4xl font-bold sm:text-5xl md:text-5xl xl:text-5xl lg:text-4xl md:leading-tight font-heptaslab">
            Our Community
          </h1>
          <p className="text-lg font-normal text-[#101213] lg:text-xl sm:px-16 xl:px-48">
            We’ve been privileged to have so many people share their stories
            through our platform. Here’s our community of speakers.
          </p>
        </div>

        <div
          id="default-carousel"
          className="relative object-cover pt-[200px] pb-[100px] sm:pt-[550px] sm:pb-[10px]"
          data-carousel="slide"
        >
          {carouselImages.map((image, index) => (
            <div
              key={index}
              className={`duration-700 ease-in-out ${
                currentSlide === index ? "" : "hidden"
              }`}
              data-carousel-item
            >
              <img
                src={image}
                className="absolute block w-full h-[300px] sm:max-w-3xl sm:h-[600px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                alt="Community images"
              />
            </div>
          ))}

          <button
            type="button"
            className="absolute top-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer start-0 group focus:outline-none"
            data-carousel-prev
            onClick={handlePrevSlide}
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-[#83977e]/80 group-hover:bg-[#83977e] group-focus:ring-4 group-focus:ring-[#101213] group-focus:outline-none">
              <svg
                className="w-4 h-4 text-[#ded1be] hover:text-[#101213] rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span className="sr-only">Previous</span>
            </span>
          </button>
          <button
            type="button"
            className="absolute top-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer end-0 group focus:outline-none"
            data-carousel-next
            onClick={handleNextSlide}
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-[#83977e]/80 group-hover:bg-[#83977e] group-focus:ring-4 group-focus:ring-[#101213] group-focus:outline-none">
              <svg
                className="w-4 h-4 text-[#ded1be] hover:text-[#101213] rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="sr-only">Next</span>
            </span>
          </button>

          {/*<div className="absolute flex space-x-3 -translate-x-1/2 bottom-5 left-1/2 rtl:space-x-reverse">
            {[...Array(totalSlides)].map((_, index) => (
              <button
                key={index}
                type="button"
                className={`w-3 h-3 rounded-full ${
                  currentSlide === index ? "bg-[#83977e]" : "bg-[#101213]"
                }`}
                aria-current={currentSlide === index}
                aria-label={`Slide ${index + 1}`}
                data-carousel-slide-to={index}
                onClick={() => handleSlideClick(index)}
              ></button>
            ))}
          </div>*/}
        </div>
      </div>

      <div className="flex flex-col mt-10 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          <a
            href="mailto:toluwase@bcporigins.com"
            target="_blank"
            rel="noopener"
            className="inline-flex justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
          >
            Join the community
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
          </a>
        </div>
    </section>
  );
};

export default CommunityPage;