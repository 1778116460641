import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Label from "shared/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  RiFacebookFill,
  RiInstagramFill,
  RiTwitterFill,
  RiLinkedinFill,
} from "react-icons/ri";
import { SiTiktok } from "react-icons/si";
import emailjs from "@emailjs/browser";
import Linkify from "react-linkify";
import faqBox from "../../images/faq-box.png";
import faqMale from "../../images/faq-male.png";
import faqFemale from "../../images/faq-female.png";

interface FAQAccordionShortItem {
  id: string;
  question: string;
  answer: string;
}

const accordionItems: FAQAccordionShortItem[] = [
  {
    id: "hs-basic-with-title-and-arrow-stretched-heading-one",
    question: "What is BCP Origins?",
    answer:
      "BCP Origins is an all-in-one invoicing, payments, contracts, communication, and collaboration platform for independent creators.",
  },
  {
    id: "hs-basic-with-title-and-arrow-stretched-heading-two",
    question: "What does it cost to use BCP Origins?",
    answer:
      "We have two payment options available: Basic and Pro. The Basic plan starts at $10/month and the Pro plan starts at $25/month. Both options include 2+ customizable rate card templates, unlimited invoicing, and automated content tracking & reporting. However, with the Pro plan, you can upload and deliver up to 10GB of content to clients or brands.",
  },
  {
    id: "hs-basic-with-title-and-arrow-stretched-heading-three",
    question: "Do you offer free trials?",
    answer:
      "We have a Forever Free Plan that allows you to customize a professional rate card, send unlimited invoices, manage multiple projects and see if BCP Origins works for you. We recommend signing up for free and upgrading when you're ready.",
  },
  {
    id: "hs-basic-with-title-and-arrow-stretched-heading-five",
    question: "Can I upgrade or downgrade my plan?",
    answer:
      "Certainly! You can easily handle your subscription, change your plan to monthly or yearly, or downgrade your subscription.",
  },
  {
    id: "hs-basic-with-title-and-arrow-stretched-heading-six",
    question: "Can I cancel my subscription at any time?",
    answer:
      "Of course! You have the freedom to cancel your subscription at any time within the billing settings. If you choose to cancel before the subscription period ends, you will still retain access to the service until that period concludes.",
  },
];

const info = [
  {
    title: "📧 Support",
    desc: "help@bcporigins.com",
  },
  {
    title: "🤝 Partnerships & Sponsorships",
    desc: "partnerships@bcporigins.com",
  },
  {
    /**
  {
    title: "☎ CALL US",
    desc: "Coming soon...",
  }
 */
  },
];

const ContactPage = () => {
  const [successToastShowing, setSuccessToastShowing] = useState(false);
  const form = useRef();

  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

  const toggleAccordion = (id: string) => {
    setActiveAccordion((prev) => (prev === id ? null : id));
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const fromName = formData.get("from_name");
    const emailAddress = formData.get("email_address");
    const message = formData.get("message");

    if (!emailAddress || !message) {
      toast.error("Please fill email and message", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!fromName) {
      toast.error("Please fill in your username", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    try {
      const response = await emailjs.sendForm(
        "bcporigins",
        "contact",
        form.current,
        {
          publicKey: "F_YeipIrO8xMAKs6k",
        }
      );

      if (response.status === 200) {
        toast(
          <div
            id="toast-simple"
            className="flex items-center w-full space-x-4 divide-x divide-[#101213] rounded-lg rtl:space-x-reverse rtl:divide-x-reverse"
            role="alert"
          >
            <svg
              className="w-8 h-8 text-[#101213] rotate-45"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
              />
            </svg>
            <div className="text-sm font-normal ps-4">
              Message sent successfully. You'll get feedback from us in 48
              hours!
            </div>
          </div>,
          {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#fed07c" },
            style: { background: "#83977e", color: "#101213" },
            onClose: () => {
              setSuccessToastShowing(true);
            },
          }
        );
      } else {
        toast.error("An error occurred while sending the message", {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            setSuccessToastShowing(true);
          },
        });
      }
    } catch (error) {
      toast.error("An error occurred while sending the message", {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          setSuccessToastShowing(true);
        },
      });
    }
  };

  useEffect(() => {
    if (successToastShowing) {
      window.location.reload();
    }
  }, [successToastShowing]);

  return (
    <div className="nc-Contact bg-[#ded1be] font-raleway">
      <div className="">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center">
          <h1 className="mb-4 text-[#101213] text-4xl font-bold sm:text-5xl md:text-5xl xl:text-5xl lg:text-4xl md:leading-tight font-heptaslab">
            Contact Us
          </h1>
        </div>
        <div className="container mx-auto max-w-7xl">
          <div className="grid flex-shrink-0 grid-cols-1 gap-12 md:grid-cols-2 ">
            <div className="max-w-sm">
              {info.map((item, index) => (
                <div key={index} className="mt-8">
                  <h3 className="text-sm font-semibold tracking-wider uppercase">
                    {item.title}
                  </h3>
                  <a href={`mailto:${item.desc}`} className="block mt-2 text-[#101213] hover:underline">{item.desc}</a>
                </div>
              ))}
              <div className="mt-8">
                <h3 className="text-sm font-semibold tracking-wider uppercase">
                  🌏 OUR SOCIALS
                </h3>
                <ul className="flex mt-3 space-x-8 justify-left items-left">
                  <li>
                    <a
                      href="https://twitter.com/bcp_origins"
                      title="Twitter"
                      target="_blank"
                      rel="noopener"
                      className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                    >
                      <RiTwitterFill className="w-6 h-6 fill-bg-[#101213] hover:text-[#83977e]" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/bcporigins"
                      title="Instagram"
                      target="_blank"
                      rel="noopener"
                      className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                    >
                      <RiInstagramFill className="w-6 h-6 fill-bg-[#101213] hover:text-[#83977e]" />
                    </a>
                  </li>
      
                  <li>
                    <a
                      href="https://www.linkedin.com/company/bcp-origins"
                      title="LinkedIn"
                      target="_blank"
                      rel="noopener"
                      className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                    >
                      <RiLinkedinFill className="w-6 h-6 fill-bg-[#101213] hover:text-[#83977e]" />
                    </a>
                  </li>

                  {/* 
                  <li>
                    <a
                      href="https://www.tiktok.com/@BCP Origins"
                      title="TikTok"
                      className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                    >
                      <SiTiktok className="w-5 h-6 fill-bg-[#101213] hover:text-[#83977e]" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.facebook.com/BCP Origins"
                      title="Facebook"
                      className="transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                    >
                      <RiFacebookFill className="w-6 h-6 fill-bg-[#101213] hover:text-[#83977e]" />
                    </a>
                  </li>
                  */}
                </ul>
              </div>
            </div>
            <div>
              <form
                ref={form}
                onSubmit={sendEmail}
                className="grid grid-cols-1 gap-6"
              >
                <label className="block">
                  <Label>Your name</Label>

                  <Input
                    placeholder="Enter your name"
                    type="text"
                    className="mt-1 capitalize"
                    name="from_name"
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    name="email_address"
                  />
                </label>
                <label className="block">
                  <Label>
                    Message{" "}
                    <span className="text-sm italic">(Type your message)</span>
                  </Label>

                  <Textarea
                    name="message"
                    placeholder=""
                    className="mt-1"
                    rows={4}
                  />
                </label>
                <div>
                  <button
                    type="submit"
                    className="inline-flex justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
                  >
                    Send message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>

      {/* 

      <div className="grid gap-10 md:grid-cols-5">
        <div className="md:col-span-2">
          <div className="max-w-xs">
            <h2 className="text-4xl font-bold sm:text-5xl md:text-5xl xl:text-5xl lg:text-4xl md:leading-tight font-heptaslab">
              FAQs
            </h2>
            <p className="mt-1 text-[#101213] md:block">
              Answers to some of our most frequently asked questions.
            </p>
          </div>
          <img className="mt-8 w-[180px] h-[180px]" src={faqMale} alt="faq" />
        </div>

        <div className="md:col-span-3">
          <div className="divide-y divide-[#83977e] hs-accordion-group">
            {accordionItems.map((item) => (
              <div
                className={`hs-accordion pt-6 pb-3 ${
                  activeAccordion === item.id ? "active" : ""
                }`}
                key={item.id}
              >
                <button
                  className="inline-flex items-center justify-between w-full pb-3 font-medium text-left text-[#101213] transition hs-accordion-toggle group gap-x-3 md:text-lg hover:text-[#101213]"
                  onClick={() => toggleAccordion(item.id)}
                  aria-controls={`hs-${item.id}-collapse`}
                  aria-expanded={activeAccordion === item.id}
                >
                  <span
                    className={`hs-accordion-title ${
                      activeAccordion === item.id
                        ? "text-[#83977e] text-[18px] group-hover:text-primary-6000"
                        : "group-hover:text-[#83977e] text-[18px]"
                    }`}
                  >
                    {item.question}
                  </span>
                  <svg
                    className={`hs-accordion-active ${
                      activeAccordion === item.id ? "hidden" : "block"
                    } w-3 h-3 text-[#101213] group-hover:text-[#83977e]`}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <svg
                    className={`hs-accordion-active ${
                      activeAccordion === item.id ? "block" : "hidden"
                    } w-3 h-3 text-[#83977e]`}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <div
                  id={`hs-${item.id}-collapse`}
                  className={`hs-accordion-content ${
                    activeAccordion === item.id ? "block" : "hidden"
                  } w-full overflow-hidden transition-[height] duration-300`}
                >
                  <p className="text-sm text-[#101213]">
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          href={decoratedHref}
                          key={key}
                          className="font-semibold underline text-[#101213] hover:text-[#83977e]"
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {item.answer}
                    </Linkify>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      */}
    </div>
  );
};

export default ContactPage;