import React from "react";
import Heading1 from "../../components/Heading/Heading1";
import Heading3 from "../../components/Heading/Heading3";
import bcplogo from "../../images/bcplogo.png";
import { Link } from "react-router-dom";

const WhoWeAre = () => {
  return (
    <section className="bg-[#ded1be] pb-6 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <Heading3
          heading="Who We Are"
          subHeading={
            <p className="mt-4">
              BCP stands for Brand, Customers, & Products. We are committed
              to helping people build global brands by helping them understand
              the relationship between brands, their products/services and the
              customers they serve. We do these primarily through creative
              storytelling, and by creating platforms where people can share
              their stories.
            </p>
          }
        />
        <div className="pt-2">
          <div className="text-[#101213]">
            <div className="">
              <Link
                to={"/about"}
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                Learn more "Brand, Customers, & Products"
              </Link>
              <a
                href="https://bcporigins.notion.site/BCP-Website-update-753b464745394b55a65cf1f8c0c59857"
                target="_blank"
                rel="noopener"
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p className="mt-4">How BCP works</p>
              </a>
            </div>
          </div>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;