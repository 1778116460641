// Footer
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const footerContent = {
  company: {
    title: "Company",
    links: [
      { title: "BCP Origins" },
      { title: "BCP Speakers 2024" },
      { title: "Volunteer" },
    ],
  },
  events: {
    title: "Events",
    links: [
      { title: "BCP 2023 Recap" },
      { title: "BCP 2024 Program Schedule" },
      { title: "Sponsor a BCP Event" },
    ],
  },
  support: {
    title: "Resources",
    links: [
      { title: "FAQs" },
      { title: "Toluwase's Blog" },
      { title: "Community" },
    ],
  },
  platform: {
    title: "Platform",
    links: [
      { title: "Privacy Policy" },
      { title: "Terms of Service" },
    ],
  },
  subscribe: {
    title: "Subscribe to the ORIGINS newsletter",
    subtitle: "Get the latest news and updates",
  },
  socials: [
    { icon: TwitterIcon, url: 'https://twitter.com/bcp_origins' },
    { icon: InstagramIcon, url: 'https://www.instagram.com/bcporigins' },
    { icon: LinkedInIcon, url: 'https://www.linkedin.com/company/bcp-origins' },
  ],
  copyright: {
    left: "© 2024 BCP Origins, All Rights Reserved.",
  },
};