import React from "react";
import Heading2 from "components/Heading/Heading2";

const AboutPage = () => {
  return (
    <section className="bg-[#ded1be] pb-20 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center">
          <h1 className="mb-4 text-[#101213] text-4xl font-bold sm:text-5xl md:text-5xl xl:text-5xl lg:text-4xl md:leading-tight font-heptaslab">
            BCP Origins
          </h1>
          <p className="mb-8 text-lg font-normal text-[#101213] lg:text-xl sm:px-16 xl:px-48">
            At BCP Origins, we believe in the power of creative storytelling.
            Stories, if told in a certain way, inspire us, move us to go beyond
            ourselves, and take action. We believe great things can be done from
            seemingly small places.
          </p>
        </div>

        <section>
          <Heading2 heading="Overview" />
          <div className="grid grid-cols-1 gap-8 mb-10 text-[#101213]">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold">Our vision</h1>
              <p className="font-medium text-[18px] mb-4">
                Building global brands locally.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8 mb-10 text-[#101213]">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold">Our mission</h1>
              <p className="font-medium text-[18px] mb-4">
                T0 tell stories that inspire people to go beyond themselves,
                light a fire in their hearts cause a paradigm shift and cause
                change in their society.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8 mb-10 text-[#101213]">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold">Roadmap</h1>
              <p className="font-medium text-[18px] mb-4">
                T0 tell stories that inspire people to go beyond themselves,
                light a fire in their hearts cause a paradigm shift and cause
                change in their society.
              </p>

              <ol className="items-center sm:flex">
                <li className="relative mb-6 sm:mb-0">
                  <div className="flex items-center">
                    <div className="z-10 flex items-center justify-center w-6 h-6 bg-[#83977e] sm:bg-[#ded1be] rounded-full ring-0 ring-[#83977e] sm:ring-8 shrink-0">
                      <svg
                        className="w-2.5 h-2.5 text-[#101213]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                      </svg>
                    </div>
                    <div className="hidden sm:flex w-full bg-[#83977e] h-0.5"></div>
                  </div>
                  <div className="mt-3 sm:pe-8">
                    <h3 className="text-lg font-semibold text-[#101213] font-heptaslab">
                      2022
                    </h3>
                    <time className="block mb-4 mt-1.5 text-[#101213]">
                      BCP Pilot
                    </time>
                    <p className="text-base font-normal text-text-[#101213]">
                      120 people were hosted with three keynote speakers.
                    </p>
                  </div>
                </li>
                <li className="relative mb-6 sm:mb-0">
                  <div className="flex items-center">
                    <div className="z-10 flex items-center justify-center w-6 h-6 bg-[#83977e] sm:bg-[#ded1be] rounded-full ring-0 ring-[#83977e] sm:ring-8 shrink-0">
                      <svg
                        className="w-2.5 h-2.5 text-[#101213]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                      </svg>
                    </div>
                    <div className="hidden sm:flex w-full bg-[#83977e] h-0.5"></div>
                  </div>
                  <div className="mt-3 sm:pe-8">
                    <h3 className="text-lg font-semibold text-[#101213] font-heptaslab">
                      2023
                    </h3>
                    <time className="block mb-4 mt-1.5 text-[#101213]">
                      BCP ‘23
                    </time>
                    <p className="text-base font-normal text-[#101213]">
                      2,170 people were hosted, with over 20 speakers and
                      panelists.
                    </p>
                  </div>
                </li>

                <li className="relative mb-6 sm:mb-0">
                  <div className="flex items-center">
                    <div className="z-10 flex items-center justify-center w-6 h-6 bg-[#83977e] sm:bg-[#ded1be] rounded-full ring-0 ring-[#83977e] sm:ring-8 shrink-0">
                      <svg
                        className="w-2.5 h-2.5 text-[#101213]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                      </svg>
                    </div>
                    <div className="hidden sm:flex w-full bg-[#83977e] h-0.5"></div>
                  </div>
                  <div className="mt-3 sm:pe-8">
                    <h3 className="text-lg font-semibold text-[#101213] font-heptaslab">
                      2023
                    </h3>
                    <time className="block mb-4 mt-1.5 text-[#101213]">
                      Launched BCP Origins Mini Experience
                    </time>
                    <p className="text-base font-normal text-[#101213]">
                      Community clusters across Nigeria — Akure, Ondo, Lagos,
                      Ife, Abuja, Ekiti.
                    </p>
                  </div>
                </li>

                <li className="relative mb-6 bg-[#83977e] rounded-lg pl-1.5 pb-2 sm:mb-0 animate-pulse">
                  <div className="flex items-center">
                    <div className="z-10 flex items-center justify-center w-6 h-6 bg-[#ded1be] rounded-full ring-0 ring-[#83977e] sm:ring-8 shrink-0">
                      <svg
                        className="w-2.5 h-2.5 text-[#101213]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                      </svg>
                    </div>
                    <div className="hidden sm:flex w-full bg-[#83977e] h-0.5"></div>
                  </div>
                  <div className="mt-3 sm:pe-8">
                    <h3 className="text-lg font-semibold text-[#101213] font-heptaslab">
                      2024
                    </h3>
                    <time className="block mb-4 mt-1.5 text-[#101213]">
                      BCP'24{" "}
                      <a
                        href="https://tix.africa/discover/bcp24"
                        target="_blank"
                        rel="noopener"
                        className="inline-flex ml-2 justify-center items-center py-0.5 px-3 text-base font-medium text-center text-[#101213] rounded-full border-2 border-[#101213] hover:bg-[#101213] hover:border-[#83977e] hover:text-[#ded1be]"
                      >
                        Get a ticket
                        <svg
                          className="w-6 h-6 ml-2"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </a>
                    </time>
                    <p className="text-base font-normal text-[#101213]">
                      A convening of 5,000 people across a 2-day event focused on
                      accessing opportunities.
                    </p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          {/** 

          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <a
              href="roadmap link"
              target="_blank"
              rel="noopener"
              className="inline-flex justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
            >
              View full roadmap
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </a>
          </div>
          */}
        </section>

        <hr className="h-0.5 my-12 bg-[#83977e] border-0" />

        <section>
          <Heading2 heading="About BCP" />
          <div className="grid grid-cols-1 gap-8 text-[#101213]">
            <div className="col-span-1">
              <p className="font-medium text-[18px] mb-4">
                Brand, Customers & Products [BCP] is a platform leveraging creative storytelling to teach people how to people build global brands. Our focus is on the relationship between brands, their customers, and the services/products they offer to their customers.
              </p>
              <p className="font-medium text-[18px] mb-4">
                In 2022, we hosted our first flagship event, BCP, focusing on the relationship between brands, customers and their products. With an audience size of 120 people, we counted our pilot edition as a success.
              </p>
              <p className="font-medium text-[18px] mb-4">
                In 2023, BCP'23:{" "}
                <strong className="italic">Building Global Brands</strong>{" "}
                happened in Akure, Ondo State, with an audience of 2,170 people, including startup founders, freelancers, business enthusiasts, entrepreneurs, and students. We also had a small percentage of people from the government represented.
              </p>
              <p className="font-medium text-[18px] mb-4">
                In 2024, BCP'24:{" "}
                <strong className="italic">
                  Accessing Global Opportunities
                </strong>{" "}
                happening in Akure, Ondo State, we intend to serve a combined audience of 5,000 people across a 2-day event focused on opportunities in the Internet era and the Web 3 space.
              </p>
            </div>
          </div>
        </section>

        <hr className="h-0.5 my-12 bg-[#83977e] border-0" />

        <section>
          <Heading2 heading="The Dream of Toluwase Olugbemiro" />
          <div className="grid grid-cols-1 gap-8 text-[#101213]">
            <div className="col-span-1">
              <p className="font-medium text-[18px] mb-4">
                When I was a child, my mother told me I would be great. I
                believed her. However, I didn’t know that greatness is not
                limited to the four walls of the missionary primary school I
                attended.
              </p>
              <p className="font-medium text-[18px] mb-4">
                I realized early on that some things were out of my reach
                because ‘I didn’t know’. I didn’t have access to the right
                information that would have helped me position myself for such
                opportunities. Many good things have happened to me by chance; I
                keep asking myself, “What if I didn’t know this?”
              </p>
              <p className="font-medium text-[18px] mb-4">
                And so today, the world is expanding, and it is doing so
                rapidly. With many of us growing alongside the internet and some
                being born into the internet age, we have a generation of people
                who were born into a world saturated with opportunities.
                However, we still have the problem I faced as a growing child;
                my mother told me I would be great, but I didn’t know how.
              </p>
              <p className="font-medium text-[18px] mb-10">
                BCP ‘24 is focused on educating and informing its audience on
                how to access global opportunities. Our focus for this year cuts
                across opportunities on the internet and beyond it — the Web 3
                ecosystem.
              </p>
            </div>
          </div>

          <a
            href="https://tix.africa/discover/bcp24"
            target="_blank"
            rel="noopener"
            className="inline-flex justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
          >
            Get a ticket to BCP'24
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>
        </section>
      </div>
    </section>
  );
};

export default AboutPage;