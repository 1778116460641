import React from 'react';
import EventsPage from './EventsPage';

const Events = () => {

  return (
    <div className="pt-32 bg-[#ded1be]">
      <EventsPage />
    </div>
  );
};

export default Events;