import React from "react";
import { ReactNode } from "react";

export interface Heading3Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading3: React.FC<Heading3Props> = ({
  className = "font-heptaslab",
  heading = "",
  subHeading,
}) => {
  return (
    <div className={`mb-8 lg:mb-10`}>
      <h2 className="text-4xl font-semibold sm:text-5xl font-heptaslab md:text-5xl lg:text-5xl xl:text-5xl">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400 font-raleway">
        </span>
      )}
    </div>
  );
};

export default Heading3;
