import React, { useState } from "react";
import bcplogo from "../images/bcplogo.png";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-[#ded1be] font-raleway fixed w-full z-20 top-0 left-0 border-b-4 border-[#fed07c]">
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
        <Link to={"/"} className="flex items-center">
          <img
            src={bcplogo}
            className="h-8 mr-3 lg:h-10 xl:h-10"
            alt="BCP Logo"
          />
        </Link>
        <div className="flex md:order-2">
          <a
            href="https://tix.africa/bcp24"
            target="_blank"
            rel="noopener"
            type="button"
            className="inline-flex justify-center items-center py-2.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
          >
            Get a ticket
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </a>

          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-[#101213] rounded-lg md:hidden hover:bg-[#ded1be] focus:outline-none focus:ring-2 focus:ring-[#ded1be]"
            aria-controls="navbar-sticky"
            aria-expanded={isMenuOpen}
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
            isMenuOpen ? "block" : "hidden"
          }`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col font-heptaslab p-4 md:p-0 mt-4 font-semibold border border-[#ded1be] rounded-lg bg-[#ded1be] md:flex-row md:space-x-8 md:mt-0 md:border-0">
            <li>
              <Link
                to={"/about"}
                className={`block py-2 pl-3 pr-4 ${
                  location.pathname === "/about"
                    ? "text-[#83977e]"
                    : "text-[#101213] hover:text-[#83977e]"
                }`}
                onClick={closeMenu}
              >
                BCP Origins
              </Link>
            </li>
            <li>
              <Link
                to={"/events"}
                className={`block py-2 pl-3 pr-4 ${
                  location.pathname === "/events"
                    ? "text-[#83977e]"
                    : "text-[#101213] hover:text-[#83977e]"
                }`}
                onClick={closeMenu}
              >
                Events
              </Link>
            </li>
            <li>
              <Link
                to={"/community"}
                className={`block py-2 pl-3 pr-4 ${
                  location.pathname === "/community"
                    ? "text-[#83977e]"
                    : "text-[#101213] hover:text-[#83977e]"
                }`}
                onClick={closeMenu}
              >
                Community
              </Link>
            </li>
            <li>
              <Link
                to={"/contact"}
                className={`block py-2 pl-3 pr-4 ${
                  location.pathname === "/contact"
                    ? "text-[#83977e]"
                    : "text-[#101213] hover:text-[#83977e]"
                }`}
                onClick={closeMenu}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;