import React from "react";
import Heading1 from "../../components/Heading/Heading1";
import Heading3 from "../../components/Heading/Heading3";

import fullGap from "../../images/sponsorLogos/fullgap.png";
import blueRoomCare from "../../images/sponsorLogos/blueroomcare.png";
import futureAcademy from "../../images/sponsorLogos/future-academy.png";
import travella from "../../images/sponsorLogos/travella.png";
import emergingCommunitiesAfrica from "../../images/sponsorLogos/emerging-communities-africa.png";
import futa from "../../images/sponsorLogos/futa.png";

const Sponsors = () => {
  return (
    <section className="bg-[#ded1be] pb-6 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <Heading3
          heading="Past Sponsors"
          subHeading={
            <span className="block font-raleway mt-3 text-[#101213]">
              We have had amazing sponsors over the years
            </span>
          }
        />

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
          <img className="flex items-center justify-center w-[250px] h-[250px]" src={fullGap} alt="Fullgap Logo" />

          <img className="flex items-center justify-center w-[250px] h-[250px]" src={blueRoomCare} alt="Blueroomcare Logo" />

          <img className="flex items-center justify-center w-[250px] h-[250px]" src={futureAcademy} alt="Future Academy Logo" />

          <img className="flex items-center justify-center w-[250px] h-[250px]" src={travella} alt="Travella Logo" />

          <img className="flex items-center justify-center w-[250px] h-[250px]" src={emergingCommunitiesAfrica} alt="Emerging Communities Africa Logo" />

          <img className="flex items-center justify-center w-[250px] h-[250px]" src={futa} alt="Futa Logo" />
        </div>

        <a
          href="https://drive.google.com/file/d/1LtO19jTXKnyXQTFhl5lbmDhPCI10BLtU/view?usp=drivesdk"
          target="_blank"
          rel="noopener"
          className="inline-flex mt-10 justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
        >
          Become a sponsor
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </a>

        <hr className="h-0.5 my-12 bg-[#83977e] border-0" />
      </div>
    </section>
  );
};

export default Sponsors;