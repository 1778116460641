import React from "react";
import { ReactNode } from "react";

export interface Heading1Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading1: React.FC<Heading1Props> = ({
  className = "font-heptaslab",
  heading = "",
  subHeading,
}) => {
  return (
    <div className={`mb-2 ${className}`}>
      <h2 className="text-xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
        </span>
      )}
    </div>
  );
};

export default Heading1;
