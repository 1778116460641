import React from "react";
import Heading3 from "../../components/Heading/Heading3";
import toluwase from "../../images/toluwase.png";

const Chief = () => {
  return (
    <section className="bg-[#ded1be] pb-6 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <Heading3 heading="Meet our Lead Convener" />
        <div>
          <div className="grid gap-8 text-[#101213] sm:gap-12 grid-cols-1 sm:grid-cols-2">
            <div className="col-span-1 text-base">
              <p className="mb-4 text-base">
                To Toluwase Olugbemiro, stories are exceptional and they have
                the power to surpass logical reasoning and cause one to move
                towards their dreams.
              </p>

              <p className="mb-4">
                In 2021, he started a conversational series where he invited
                people to talk and share stories about themselves. The goal was
                to find answers to the many questions he had in his mind, but
                this eventually evolved into something more.
              </p>

              <p className="mb-4">
                In 2022, he spoke to Uche Ugo who proposed an idea, "rater than
                have a virtual event, if it were possible to turn it into
                something tangible". Toluwase picked up on it, and that's how
                BCP Origins came to be.
              </p>

              <p className="mb-4">
                Toluwase believes in the electrifying power of stories, he
                always thought, "if stories changed his life this much, think
                how much it would change the lives of millions across the
                world". Welcome to his dream, a world filled with powerful
                stories, stirring others up to dream.
              </p>

              <a
                href="https://tolugbemiro.medium.com"
                target="_blank"
                rel="noopener"
                className="inline-flex mt-10 justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
              >
                Read his blog
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 ml-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
              </a>
            </div>
            <div className="col-span-1">
              <img src={toluwase} alt="Toluwase Olugbemiro" />
            </div>
          </div>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />
        </div>
      </div>
    </section>
  );
};

export default Chief;