// @ts-nocheck
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { footerContent } from "../utils/content";
import Title from "../shared/Title/Title";
import Heading1 from "../components/Heading/Heading1";
import { Link } from "react-router-dom";

const { company, events, support, platform, copyright, socials } =
  footerContent;

const LinkSection = ({ title, links }) => (
  <Stack spacing={2.5}>
    <Title>{title}</Title>

    {links.map(({ title }) => (
      <Typography
        key={title}
        variant="body2"
        color="text.secondary"
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: "text.primary",
          },
        }}
      >
        {title}
      </Typography>
    ))}
  </Stack>
);

const Footer = () => {
  return (
    <Box className="bg-[#83977e] font-raleway">
      <Divider sx={{ mb: 10 }} />

      <Container>
        <Grid container spacing={8} flexWrap="wrap-reverse">
          {/* Links
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <Grid container spacing={2}>
          
              <Grid item xs={6} sm={3} md={6} lg={3}>
                <LinkSection {...company} />
              </Grid>

              <Grid item xs={6} sm={3} md={6} lg={3}>
                <LinkSection {...events} />
              </Grid>

           
              <Grid item xs={6} sm={3} md={6} lg={3}>
                <LinkSection {...support} />
              </Grid>


              <Grid item xs={6} sm={3} md={6} lg={3}>
                <LinkSection {...platform} />
              </Grid>
            </Grid>
          </Grid>
           */}

          {/* Subscribe */}
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <Stack>
              <Heading1
                heading="Subscribe to the ZERO TO ONE newsletter"
                subHeading={
                  <span className="block font-raleway mt-3 text-[#101213]">
                    Get the latest news and updates
                  </span>
                }
              />

              <a
                href="https://bcporigins.substack.com"
                target="_blank"
                rel="noopener"
                className="inline-flex mt-4 mb-6 justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#101213] rounded-full border-2 border-[#101213] hover:bg-[#101213] hover:border-[#83977e] hover:text-[#ded1be]"
              >
                SUBSCRIBE
                <svg
                  className="w-6 h-6 ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </a>

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent=""
                flexWrap="wrap"
              >
                {socials.map((item, i) => (
                  <Link to={item.url} key={i} target="_blank" rel="noopener">
                    <IconButton key={i} className="hover:text-[#ded1be]">
                      <item.icon />
                    </IconButton>
                  </Link>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Divider className="bg-[#ded1be] p-[0.5px]" sx={{ mt: 6, mb: 5 }} />

        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ pb: 6 }}
        >
          <Typography variant="body2" color="text.secondary">
            {copyright.left}
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;