import React from "react";

const Hero = () => {
  return (
    <section className="bg-[#ded1be] font-raleway">
      <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-12">
        <a
          href="mailto:partnerships@bcporigins.com"
          className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm bg-[#101213] rounded-full"
          role="alert"
        >
          <span className="text-xs bg-[#83977e] rounded-full font-raleway text-[#101213] font-semibold px-4 py-1.5 mr-3">
            Partnerships
          </span>{" "}
          <span className="text-xs lg:text-sm sm:text-xs xl:text-sm md:text-sm text-[#ded1be] font-medium font-raleway">
            Bring BCP to a place near you
          </span>
          <svg
            className="w-5 h-5 ml-2"
            fill="#101213"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
        <h1 className="mb-4 mt-2 text-4xl font-extrabold tracking-tight leading-none md:text-6xl sm:text-6xl lg:text-7xl text-[#101213] font-heptaslab">
          Accessing Global Opportunities
        </h1>
        <p className="mb-8 text-lg font-normal text-[#101213] lg:text-xl sm:px-16 xl:px-48">
          BCP is an annual conference that brings together experts in Brands, Customers, Products and all that connects them to share ideas and collaborate.
        </p>
        <div className="flex flex-col mb-8 space-y-4 lg:mb-16 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          <a
            href="https://tix.africa/bcp24"
            target="_blank"
            rel="noopener"
            className="inline-flex justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
          >
            Get your ticket for BCP'24
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
              />
            </svg>
          </a>
        </div>
        {/*
        <section>
          <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-10 lg:px-6">
            <dl className="grid max-w-screen-md gap-8 mx-auto text-[#101213] sm:grid-cols-3">
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-4xl font-extrabold md:text-5xl sm:text-5xl font-heptaslab">
                  5
                </dt>
                <dd className="font-normal">events</dd>
              </div>
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-4xl font-extrabold md:text-5xl sm:text-5xl font-heptaslab">
                  3
                </dt>
                <dd className="font-normal">cities</dd>
              </div>
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-4xl font-extrabold md:text-5xl sm:text-5xl font-heptaslab">
                  3.5k+
                </dt>
                <dd className="font-normal">lives impacted</dd>
              </div>
            </dl>
          </div>
        </section>
         */}
      </div>
    </section>
  );
};

export default Hero;
