import React from "react";
import Heading1 from "../../components/Heading/Heading1";
import Heading3 from "../../components/Heading/Heading3";
import bcplogo from "../../images/bcplogo.png";
import { TbCurrencyNaira } from "react-icons/tb";

const Impact = () => {
  return (
    <section className="bg-[#ded1be] pb-6 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <Heading3
          heading="Our impact"
          subHeading={
            <p className="mt-4">
              In a world where the overwhelming power of stories is seen in the
              lives lived the stories we have told have created a ripple effect
              across different lives across the world.
            </p>
          }
        />
        <div className="pt-4">
          <dl className="grid mx-auto gap-10 text-[#101213] grid-cols-2 sm:grid-cols-3">
            <div className="flex flex-col">
              <dt className="mb-2 text-4xl font-extrabold md:text-5xl sm:text-5xl font-heptaslab">
                3000+
              </dt>
              <dd className="font-normal">lives impacted</dd>
            </div>
            <div className="flex flex-col">
              <dt className="mb-2 text-4xl font-extrabold md:text-5xl sm:text-5xl font-heptaslab">
                50+
              </dt>
              <dd className="font-normal">stories shared</dd>
            </div>
            <div className="flex flex-col">
              <dt className="inline-flex mb-2 text-4xl font-extrabold md:text-5xl sm:text-5xl font-heptaslab">
                <TbCurrencyNaira className="w-[40px] h-[40px] sm:w-[50px] sm:h-[50px]" />20M
              </dt>
              <dd className="font-normal">raised to share stories and spread ideas</dd>
            </div>
          </dl>

          <a
            href="mailto:partnerships@bcporigins.com"
            target="_blank"
            rel="noopener"
            className="inline-flex mt-10 justify-center items-center py-3.5 px-6 text-base font-medium text-center text-[#ded1be] rounded-full hover:bg-[#83977e] bg-[#101213] hover:text-[#101213]"
          >
            Work with us
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </a>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />
        </div>
      </div>
    </section>
  );
};

export default Impact;