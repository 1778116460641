import React from 'react';
import CommunityPage from './CommunityPage';

const Community = () => {
  
  return (
    <div className="pt-32 bg-[#ded1be]">
      <CommunityPage />
    </div>
  );
};

export default Community;
