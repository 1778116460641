import React from "react";
import Hero from "./HeroSection/Hero";
import Sponsors from "./Sponsors/Sponsors";
import WhatWeDo from "./WhatWeDo/WhatWeDo";
import Impact from "./Impact/Impact";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import Chief from "./Chief/Chief";
import Contact from "./Contact/Contact";

const Home = () => {
  return (
    <div className="pt-32 bg-[#ded1be]">
      <Hero />
      <WhatWeDo />
      <Impact />
      <WhoWeAre />
      <Sponsors />
      <Chief />
      <Contact />
    </div>
  );
};

export default Home;