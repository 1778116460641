import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "routers/ScrollToTop";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
//
import Home from "./Home/Home";
import About from "./Pages/AboutPage/About";
import Events from "./Pages/EventsPage/Events";
import Community from "./Pages/CommunityPage/Community";
import Contact from "Pages/ContactsPage/Contact";
import ComingSoon from "Pages/ComingSoon/ComingSoon";

const App = () => (
  <BrowserRouter basename="/">
    <div className="App">
      <Navbar />
      <ScrollToTop />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/events" element={<Events />} />
        <Route path="/community" element={<Community />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/coming" element={<ComingSoon />} />
      </Routes>

      <Footer />
    </div>
  </BrowserRouter>
);

export default App;