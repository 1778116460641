import React from 'react';

const ComingSoon = () => {
  
  return (
    <div className="pt-48 pb-48 sm:pt-72 sm:pb-72 bg-[#ded1be]">
        <div className="px-4 py-8 mx-auto text-center">
          <h1 className="animate-pulse mb-4 text-[#101213] text-4xl font-bold sm:text-5xl md:text-5xl xl:text-5xl lg:text-4xl md:leading-tight font-heptaslab">
            Coming Soon
          </h1>
          <p className="text-lg font-normal text-[#101213] lg:text-xl sm:px-16 xl:px-48 font-raleway">
            We're working hard to bring you something amazing. Stay tuned!
          </p>
        </div>
    </div>
  );
};

export default ComingSoon;