import React, { useState, useEffect, useRef } from "react";
import Heading3 from "../../components/Heading/Heading3";
import events from "../../images/bcp/events.jpg";
import media from "../../images/bcp/media.jpg";
import business from "../../images/bcp/business.jpg";
import { Link } from "react-router-dom";
import NcModal from "shared/NcModal/NcModal";

//BCPOrigins'23
import one from "../../images/BCPOrigins'23/1.jpg";
import two from "../../images/BCPOrigins'23/2.jpg";
import three from "../../images/BCPOrigins'23/3.jpg";
import four from "../../images/BCPOrigins'23/4.jpg";
import five from "../../images/BCPOrigins'23/19.jpg";
import six from "../../images/BCPOrigins'23/6.jpg";
import seven from "../../images/BCPOrigins'23/7.jpg";
import eight from "../../images/BCPOrigins'23/8.jpg";
import nine from "../../images/BCPOrigins'23/9.jpg";
import ten from "../../images/BCPOrigins'23/10.jpg";
import eleven from "../../images/BCPOrigins'23/11.jpg";
import twelve from "../../images/BCPOrigins'23/12.jpg";
import thirteen from "../../images/BCPOrigins'23/13.jpg";
import fourteen from "../../images/BCPOrigins'23/14.jpg";
import fifteen from "../../images/BCPOrigins'23/15.jpg";
import sixteen from "../../images/BCPOrigins'23/16.jpg";
import seventeen from "../../images/BCPOrigins'23/17.jpg";
import eighteen from "../../images/BCPOrigins'23/18.jpg";
import nineteen from "../../images/BCPOrigins'23/5.jpg";
import twenty from "../../images/BCPOrigins'23/20.jpg";

//BCP Mini Experiences'23
import oneMini from "../../images/miniExperience/1.jpg";
import twoMini from "../../images/miniExperience/2.jpg";
import threeMini from "../../images/miniExperience/3.jpg";
import fourMini from "../../images/miniExperience/4.jpg";
import fiveMini from "../../images/miniExperience/19.jpg";
import sixMini from "../../images/miniExperience/6.jpg";
import sevenMini from "../../images/miniExperience/7.jpg";
import eightMini from "../../images/miniExperience/8.jpg";
import nineMini from "../../images/miniExperience/9.jpg";
import tenMini from "../../images/miniExperience/10.jpg";
import elevenMini from "../../images/miniExperience/11.jpg";
import twelveMini from "../../images/miniExperience/12.jpg";
import thirteenMini from "../../images/miniExperience/13.jpg";
import fourteenMini from "../../images/miniExperience/14.jpg";
import fifteenMini from "../../images/miniExperience/15.jpg";
import sixteenMini from "../../images/miniExperience/16.jpg";
import seventeenMini from "../../images/miniExperience/17.jpg";
import eighteenMini from "../../images/miniExperience/18.jpg";
import nineteenMini from "../../images/miniExperience/19.jpg";
import twentyMini from "../../images/miniExperience/20.jpg";

const WhatWeDo = () => {
  const [showModal, setShowModal] = useState(false);
  const [showMiniModal, setShowMiniModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const toggleModal = () => {
    setShowModal(true);
  };

  const toggleMiniModal = () => {
    setShowMiniModal(true);
  };

  const totalSlides = 20;
  const slideInterval = 5000;
  let timerRef = useRef(null);

  const carouselImages = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
    nineteen,
    twenty,
  ];

  const carouselMiniImages = [
    oneMini,
    twoMini,
    threeMini,
    fourMini,
    fiveMini,
    sixMini,
    sevenMini,
    eightMini,
    nineMini,
    tenMini,
    elevenMini,
    twelveMini,
    thirteenMini,
    fourteenMini,
    fifteenMini,
    sixteenMini,
    seventeenMini,
    eighteenMini,
    nineteenMini,
    twentyMini,
  ];

  useEffect(() => {
    const slideNext = () => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    };

    timerRef.current = setInterval(slideNext, slideInterval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [totalSlides, slideInterval]);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
    clearInterval(timerRef.current);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    clearInterval(timerRef.current);
  };

  const renderContent = () => {
    return (
      <div>
        <div className="p-4 space-y-4 md:p-5">
          <div
            id="default-carousel"
            className="relative object-contain pt-[200px] pb-[100px] sm:pt-[550px] sm:pb-[10px]"
            data-carousel="slide"
          >
            {carouselImages.map((image, index) => (
              <div
                key={index}
                className={`duration-700 ease-in-out ${
                  currentSlide === index ? "" : "hidden"
                }`}
                data-carousel-item
              >
                <img
                  src={image}
                  className="absolute block w-full h-[300px] object-contain sm:max-w-3xl sm:h-[600px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                  alt="Community images"
                />
              </div>
            ))}

            <button
              type="button"
              className="absolute top-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer start-0 group focus:outline-none"
              data-carousel-prev
              onClick={handlePrevSlide}
            >
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-[#83977e]/80 group-hover:bg-[#83977e] group-focus:ring-4 group-focus:ring-[#101213] group-focus:outline-none">
                <svg
                  className="w-4 h-4 text-[#ded1be] hover:text-[#101213] rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
                <span className="sr-only">Previous</span>
              </span>
            </button>
            <button
              type="button"
              className="absolute top-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer end-0 group focus:outline-none"
              data-carousel-next
              onClick={handleNextSlide}
            >
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-[#83977e]/80 group-hover:bg-[#83977e] group-focus:ring-4 group-focus:ring-[#101213] group-focus:outline-none">
                <svg
                  className="w-4 h-4 text-[#ded1be] hover:text-[#101213] rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
                <span className="sr-only">Next</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderMiniContent = () => {
    return (
      <div>
        <div className="p-4 space-y-4 md:p-5">
          <div
            id="default-carousel"
            className="relative object-contain pt-[200px] pb-[100px] sm:pt-[550px] sm:pb-[10px]"
            data-carousel="slide"
          >
            {carouselMiniImages.map((image, index) => (
              <div
                key={index}
                className={`duration-700 ease-in-out ${
                  currentSlide === index ? "" : "hidden"
                }`}
                data-carousel-item
              >
                <img
                  src={image}
                  className="absolute object-contain block w-full h-[300px] sm:max-w-3xl sm:h-[600px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                  alt="Community images"
                />
              </div>
            ))}

            <button
              type="button"
              className="absolute top-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer start-0 group focus:outline-none"
              data-carousel-prev
              onClick={handlePrevSlide}
            >
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-[#83977e]/80 group-hover:bg-[#83977e] group-focus:ring-4 group-focus:ring-[#101213] group-focus:outline-none">
                <svg
                  className="w-4 h-4 text-[#ded1be] hover:text-[#101213] rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
                <span className="sr-only">Previous</span>
              </span>
            </button>
            <button
              type="button"
              className="absolute top-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer end-0 group focus:outline-none"
              data-carousel-next
              onClick={handleNextSlide}
            >
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-[#83977e]/80 group-hover:bg-[#83977e] group-focus:ring-4 group-focus:ring-[#101213] group-focus:outline-none">
                <svg
                  className="w-4 h-4 text-[#ded1be] hover:text-[#101213] rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
                <span className="sr-only">Next</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="bg-[#ded1be] pt-12 pb-6 font-raleway">
      <div className="max-w-screen-xl px-4 mx-auto">
        <Heading3 heading="What we do" />
        <div>
          <div className="grid grid-cols-1 gap-8 text-[#101213] sm:gap-12 sm:grid-col-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
            <div className="col-span-2">
              <img src={events} alt="Events" />
            </div>
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold font-heptaslab">
                Events
              </h1>
              <p className="font-medium text-[18px] mb-4">
                Create platforms where stories are told, ideas are inspired, and
                change is pioneered
              </p>
              <Link
                to={"/events"}
                target="_blank"
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p>Learn more</p>
              </Link>
              <Link
                to={"/events"}
                target="_blank"
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p className="mt-3">Explore BCP Origins Mini Experiences</p>
              </Link>
            </div>
          </div>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />

          <div className="hidden sm:hidden md:grid lg:grid xl:grid grid-cols-1 gap-8 text-[#101213] sm:gap-12 sm:grid-col-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold font-heptaslab">
                Media
              </h1>
              <p className="font-medium text-[18px] mb-4">
                Check out a catalogue of our past events
              </p>
              <a
                onClick={toggleModal}
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p>BCPOrigins'23</p>
              </a>
              <a
                onClick={toggleMiniModal}
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p className="mt-3">
                  BCP Mini Experiences'23
                </p>
              </a>
            </div>
            <div className="col-span-2">
              <img src={media} alt="Media" />
            </div>
          </div>

          <div className="grid sm:grid md:hidden lg:hidden xl:hidden grid-cols-1 gap-8 text-[#101213] sm:gap-12 sm:grid-col-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
            <div className="col-span-2">
              <img src={media} alt="Media" />
            </div>
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold font-heptaslab">
                Media
              </h1>
              <p className="font-medium text-[18px] mb-4">
                Check out a catalogue of our past events
              </p>
              <a
                onClick={toggleModal}
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p>BCPOrigins'23</p>
              </a>
              <a
                onClick={toggleMiniModal}
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p className="mt-3">
                  BCP Mini Experiences'23
                </p>
              </a>
            </div>
          </div>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />

          <div className="grid grid-cols-1 gap-8 text-[#101213] sm:gap-12 sm:grid-col-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
            <div className="col-span-2">
              <img src={business} alt="Business solutions" />
            </div>
            <div className="col-span-1">
              <h1 className="text-[28px] font-semibold font-heptaslab">
                Business solutions
              </h1>
              <p className="font-medium text-[18px] mb-4">
                Partner with us to bring BCP to a place near you
              </p>
              <a
                href="mailto:partnerships@bcporigins.com"
                target="_blank"
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p>BCP Partnerships</p>
              </a>
              <Link
                to={"/coming"}
                target="_blank"
                className="text-sm font-semibold underline hover:text-[#83977e]"
              >
                <p className="mt-3">BCP for the Workplace</p>
              </Link>
            </div>
          </div>

          <hr className="h-0.5 my-12 bg-[#83977e] border-0" />
        </div>
      </div>

      <NcModal
        renderTrigger={() => null}
        isOpenProp={showModal}
        renderContent={renderContent}
        contentExtraClass="max-w-5xl"
        onCloseModal={() => setShowModal(false)}
        modalTitle=""
      />

      <NcModal
        renderTrigger={() => null}
        isOpenProp={showMiniModal}
        renderContent={renderMiniContent}
        contentExtraClass="max-w-5xl"
        onCloseModal={() => setShowMiniModal(false)}
        modalTitle=""
      />
    </section>
  );
};

export default WhatWeDo;